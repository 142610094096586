import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Image from "../shared/image"

const Hover = styled.div`
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.15);
  }
`

const ShipperPartners = ({ className }) => {
  const data = useStaticQuery(graphql`
    query ShipperPartnersQuery {
      petsmart: file(relativePath: { eq: "petsmart-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 397, maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anheuser: file(relativePath: { eq: "anheuser-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 272, maxHeight: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tyson: file(relativePath: { eq: "tyson-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 289, maxHeight: 111) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      demkota: file(relativePath: { eq: "demkota-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 480, maxHeight: 280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={className}>
      <h1 className="text-gray-800 py-5 font-bold text-3xl text-center">
        Trusted Partners
      </h1>
      <div className="w-full">
        <div className="flex flex-wrap lg:flex-no-wrap items-center justify-around">
          <Image
            width="150px"
            mobileWidth="200px"
            tabletWidth="150px"
            fluid={data.tyson.childImageSharp.fluid}
            alt="Archerhub's Trusted Partner - Tyson" 
          />
          <Image
            width="150px"
            mobileWidth="200px"
            tabletWidth="150px"
            fluid={data.petsmart.childImageSharp.fluid}
            alt="Archer Hub's Trusted Partner - PetSmart" 
          />
          <Image
            width="150px"
            mobileWidth="200px"
            tabletWidth="150px"
            fluid={data.anheuser.childImageSharp.fluid}
            alt="Archerhub's Trusted Partner - Anheuser-Busch"
          />
          {/* <Hover>
            <Link to="/news/archerhubs-asset-based-freight-approach-means-demkota-customers-never-have-to-ask-wheres-the-beef">
              <Image
                width="150px"
                mobileWidth="200px"
                tabletWidth="150px"
                fluid={data.demkota.childImageSharp.fluid}
                alt="DemKota Ranch Beef" 
              />
            </Link>
          </Hover> */}
        </div>
      </div>
    </div>
  )
}

ShipperPartners.propTypes = {
  className: PropTypes.string,
}

ShipperPartners.defaultProps = {
  className: null,
}

export default ShipperPartners
