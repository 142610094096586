import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Image from "../shared/image"
import ShipperPartners from "./shipper-partners"
import Button from "../shared/button"

const Shipper = styled.section`
  margin: 0 auto;
  maxwidth: 100%;
  padding: 0px 1.0875rem 1.45rem;
`

const ShipperContent = ({ className }) => {
  const data = useStaticQuery(graphql`
    query ShipperContentImageQuery {
      loadboard: file(relativePath: { eq: "loadboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 3542, maxHeight: 2082) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconred: file(relativePath: { eq: "icon-red.png" }) {
        childImageSharp {
          fluid(maxWidth: 344, maxHeight: 331) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      icongreen: file(relativePath: { eq: "icon-green.png" }) {
        childImageSharp {
          fluid(maxWidth: 344, maxHeight: 331) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconblue: file(relativePath: { eq: "icon-blue.png" }) {
        childImageSharp {
          fluid(maxWidth: 344, maxHeight: 331) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconyellow: file(relativePath: { eq: "icon-yellow.png" }) {
        childImageSharp {
          fluid(maxWidth: 344, maxHeight: 331) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Shipper className={className}>
      <div className="w-full lg:w-4/5 mt-5 text-left">
        <h3 className="font-medium text-md text-center lg:text-2xl text-gray-800">
          Find the right truck at the right time at the right price, regardless
          of how much or how little you ship. As an asset-based digital freight
          broker, we combine a broad carrier network and our own backup fleet to
          move your shipment seamlessly every time. No calling dozens of
          carriers, no rate shopping, no hassles.
        </h3>
      </div>
      <div className="w-full my-20">
        <div className="flex justify-center">
          <Image
            width="1000px"
            mobileWidth="400px"
            fluid={data.loadboard.childImageSharp.fluid}
            alt="Archer hubs freight brokerage app"
          />
        </div>
      </div>
      <div className="lg:w-full">
        <div className="flex flex-wrap lg:flex-no-wrap items-center">
          <div className="lg:w-full">
            <div className="flex justify-center my-5">
              <Image
                width="100px"
                mobileWidth="80px"
                tabletWidth="100px"
                fluid={data.iconblue.childImageSharp.fluid}
                alt="Competitive rates with reliable carriers"
              />
            </div>
            <div className="lg:flex-auto my-5 text-center">
              <h4 className="font-semibold text-lg text-gray-700">
                Affordable, Reliable Trucks
              </h4>
              <p className="text-gray-700">
                No matter how tight truck capacity is, we’ll get you there on
                time. Using an asset-based approach, we find the most
                competitive rates for your load with one of our thousands of
                contracted carriers or our own fleet.
              </p>
            </div>
            <div className="flex justify-center lg:mt-10 my-5">
              <Image
                width="100px"
                mobileWidth="80px"
                tabletWidth="100px"
                fluid={data.icongreen.childImageSharp.fluid}
                alt="Shipment recovery plan"
              />
            </div>
            <div className="lg:flex-auto my-5 text-center">
              <h4 className="font-semibold text-lg text-gray-700">
                Backup When You Need It
              </h4>
              <p className="text-gray-700">
                Disruptions are inevitable in logistics &mdash; plan for the
                worst with Archerhub. A sophisticated algorithm is designed to
                identify backup trucks during transit, just in case. With our
                recovery plan in place, bumps in the road won’t throw your
                shipment off schedule.
              </p>
            </div>
          </div>
          <div className="lg:w-full lg:ml-32">
            <div className="flex justify-center my-5">
              <Image
                width="100px"
                mobileWidth="80px"
                tabletWidth="100px"
                fluid={data.iconyellow.childImageSharp.fluid}
                alt="360-Degree Visibility"
              />
            </div>
            <div className="lg:flex-auto my-5 text-center">
              <h4 className="font-semibold text-lg text-gray-700">
                360-Degree Visibility
              </h4>
              <p className="text-gray-700">
                Manage every shipment easily through our intuitive platform. Our
                transparent processes keep you connected with your freight, with
                automated load details and notifications sent right to your
                desktop or mobile phone.
              </p>
            </div>
            <div className="flex justify-center lg:mt-10 my-5">
              <Image
                width="100px"
                mobileWidth="80px"
                tabletWidth="100px"
                fluid={data.iconred.childImageSharp.fluid}
                alt="Customizable reports for your shipments"
              />
            </div>
            <div className="lg:flex-auto my-5 text-center">
              <h4 className="font-semibold text-lg text-gray-700">
                The Right Insights
              </h4>
              <p className=" text-gray-700">
                Track the metrics that matter to your business. Our customizable
                reports let you monitor and manage shipments at a glance,
                providing insight to help you maximize efficiency and
                cost-effectiveness.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-32 pb-12">
        <Link to="/signup-shipper">
          <Button
            className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 lg:py-4 px-4 lg:px-8 rounded"
            type="button"
          >
            Quote Your Next Load
          </Button>
        </Link>
      </div>
      <ShipperPartners className="w-full mt-6 lg:my-16" />
    </Shipper>
  )
}

ShipperContent.propTypes = {
  className: PropTypes.string,
}

ShipperContent.defaultProps = {
  className: null,
}

export default ShipperContent
