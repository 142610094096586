import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShipperContent from "../components/shipper/shipper-content"

const desc = "Get your shipment where it needs to go with our asset-based digital freight brokerage. Access a broad carrier network paired with our own fleet."
const Shipper = () => (
  <Layout>
    <SEO title="Shipper" description={desc}/>
    <ShipperContent className="container mx-auto flex flex-wrap justify-center" />
  </Layout>
)

export default Shipper
